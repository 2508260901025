import * as React from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";

export const DynamicHeader = () => {
  return (
    <View
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          color: "red",
          display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
          // textAlignVertical: "center",
          textAlign: "center",
          padding: 10,
          // backgroundColor: "yellow",
        }}
      >
        TEST
      </Text>
    </View>
  );
};

export default DynamicHeader;
